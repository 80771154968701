<script setup lang="ts">

</script>

<template>
  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.33 23.33">
    <polygon points="11.83 10.32 7.52 4.29 5.22 4.29 10.56 11.76 11.23 12.7 15.8 19.1 18.1 19.1 12.5 11.26 11.83 10.32"
             style="fill:currentColor; stroke-width:0px;" />
    <path
      d="m21.38,0H1.95C.87,0,0,.87,0,1.95v19.43C0,22.46.87,23.33,1.95,23.33h19.43c1.08,0,1.95-.87,1.95-1.95V1.95C23.33.87,22.46,0,21.38,0Zm-6.28,20.15l-4.63-6.59-5.79,6.59h-1.5l6.62-7.53L3.18,3.18h5.05l4.38,6.24,5.49-6.24h1.5l-6.32,7.19,6.87,9.78h-5.05Z"
      style="fill:currentColor; stroke-width:0px;" />
  </svg>
</template>

<style scoped>

</style>
